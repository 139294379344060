import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

import "./Introduction.css";

function Introduction(props) {
    const data = props;

    return (
        <div>
            <div style={{ display: "grid" }}>
                {/* You can use a GatsbyImage component if the image is dynamic */}
                <GatsbyImage
                    style={{
                        gridArea: "1/1",
                        // You can set a maximum height for the image, if you wish.
                        height: 254,
                    }}
                    // You can optionally force an aspect ratio for the generated image
                    // aspectRatio={3 / 1}
                    // This is a presentational image, so the alt should be an empty string
                    alt="alt"
                    // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash

                    loading={"eager"}

                    image={data?.backImg?.imageFile?.childImageSharp?.gatsbyImageData}

                    formats={["auto", "webp", "avif"]}
                />
                <div
                    style={{
                        // By using the same grid area for both, they are stacked on top of each other
                        gridArea: "1/1",
                        position: "relative",
                        // This centers the other elements inside the hero component
                        placeItems: "center",
                        display: "grid",
                    }}
                >
                    {/* Any content here will be centered in the component */}
                    <div className="flex justify-center items-center trans-filter ">
                        <div className="intro-ld">
                            <h1>{data.title}</h1>

                            {/* breadcrumbAcf */}

                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Introduction;