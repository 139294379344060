import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/Layout"
import axios from "axios"
import arrowLeft from "../images/long-arrow-alt-right-solid.svg"

import { sha256 } from 'js-sha256';

import Introduction from "../components/Global/Introduction"
import "../components/Contact/ContactTemplate.css"



function ContactTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;
    const [formError, setFormError] = useState("")
    const [emailResponse, setEmailResponse] = useState("")
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://kleidaras-varvaridis.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    function contactUs() {
        let fullName = document.getElementById("fullName").value
        let email = document.getElementById("email").value
        let telephone = document.getElementById("telephone").value
        let txtArea = document.getElementById("txtArea").value

        if (fullName.length > 4) {
            if (telephone.length >= 10) {
                if (validateEmail(email)) {
                    if (txtArea.length >= 10) {
                        console.log("SEND EMAIL")
                        let payload = {
                            fullName: fullName,
                            email: email,
                            message: txtArea,
                            telephone: telephone
                        }

                        setLoading(true)

                        axios.post(`https://admin.kleidaras-varvaridis.gr/wp-json/myplugin/v1/contactEmail`, payload)
                            .then(function (response) {
                                // handle success
                                setEmailFailed(false)
                                setEmailSent(true)
                                setEmailResponse(response.data)
                                setLoading(false)

                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'website_form_submitted'
                                });
                            })
                            .catch(function (error) {
                                // handle error
                                setEmailFailed(true)
                                setEmailResponse(data.responses.failed)
                                setLoading(false)
                            })

                        setFormError("")
                    } else {
                        setFormError("Το μήνυμά σας θα πρέπει να ξεπερνά τους 9 χαρακτήρες")
                    }
                } else {
                    setFormError("Το email σας δεν είναι έγκυρο")
                }
            } else {
                setFormError("Ο αριθμός τηλεφώνου δεν είναι έγκυρος")
            }
        } else {
            console.log(fullName.length)
            if (fullName.length === 0) {
                setFormError("Συμπληρώστε το ονοματεπώνυμό σας")
            } else {
                setFormError("To ονοματεπώνυμο θα πρέπει να ξεπερνά τους 4 χαρακτήρες")
            }
        }


    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email)?.toLowerCase());
    }

    return (
        <Layout
            header={data.menu}
            footer={data.footer}
            services={data.services}
            productCategories={data.productCategories}
            metaData={constructMetaData(data.page, props.pageContext.currentPage)}
        >
            <div>
                {console.log(data.page)}
                <section>
                    <Introduction backImg={data.page.contactPageAcf.introductionBackgroundImage} title={data.page.contactPageAcf.introductionHeader} />
                </section>
                <section className="container m-auto my-20 px-4 md:px-0">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-2/3">
                            <div className="contact-editor pb-12" dangerouslySetInnerHTML={{ __html: data.page.contactPageAcf.contactContent }} />
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24233.829857081753!2d22.963780093191872!3d40.6027768059902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd998f2589744818!2zzprOm86VzpnOlM6RzqHOkc6jIDIzMTA2MDA2MDA!5e0!3m2!1sel!2sgr!4v1627835878208!5m2!1sel!2sgr" width="100%" height="450" style={{border: "0"}} allowfullscreen="" loading="lazy"></iframe>                        </div>
                        <div className="w-full md:w-1/3 pl-0 lg:pl-8">
                            <div className="ss-r-tab" style={{height: "auto"}}>
                                {emailFailed && (
                                    <p style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                                )}

                                {!emailSent
                                    ?
                                    <div className="cu-question ">
                                        <h2 className="ss-side-title">{data.page.contactPageAcf.questionTitle}</h2>
                                        <div className="flex flex-wrap">
                                            <div className="w-full pt-4">
                                                <label for="fullName">{data.page.contactPageAcf.questionGroup.name}</label>
                                                <input type="text" name="fullName" id="fullName" />
                                            </div>
                                            <div className="w-full pt-4 ">
                                                <label for="telephone">{data.page.contactPageAcf.questionGroup.mobileNumber}</label>
                                                <input type="text" name="telephone" id="telephone" />
                                            </div>
                                            <div className="w-full pt-4 ">
                                                <label for="email">{data.page.contactPageAcf.questionGroup.email}</label>
                                                <input type="text" name="email" id="email" />
                                            </div>
                                            <div className="w-full mt-4" >
                                                <label for="txtArea">{data.page.contactPageAcf.questionGroup.message}</label>
                                                <textarea id="txtArea" />
                                            </div>
                                            <div className="w-full mt-4">
                                                <button onClick={contactUs} className="h-appointment-btn w-full flex justify-center items-center" style={{height:"50px", borderRadius:"0"}} >
                                                    {!loading ?
                                                        <div className="flex justify-between items-center">
                                                            {data.page.contactPageAcf.questionGroup.button}
                                                            <img src={arrowLeft} width={14} className="ml-2" />
                                                        </div>
                                                        :
                                                        <div className="bttn-loader">Loading...</div>
                                                    }

                                                </button>
                                                {formError !== "" && (
                                                    <span className="w-full text-center block pt-4" style={{ color: "red" }}>{formError}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div >
                                        <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export default ContactTemplate;

export const pageQuery = graphql`query GET_CONTACT_PAGE($id: ID!) {
  wpgraphql {
    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      breadcrumbAcf {
        breadcrumbName
      }
      contactPageAcf {
        introductionHeader
        introductionBackgroundImage {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        contactContent
        contactMap {
            sourceUrl
            altText
            imageFile {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
                }
            }
        }
        questionTitle
        questionGroup {
          name
          mobileNumber
          email
          message
          button
        }
      }
    }
    menu: template(id:"cG9zdDozMTIz", idType:ID){
        menuAcf{
          menu{
            menuGroup{
              url
              label
              isnested
              childnodes{
                url
                label
              }
            }
          }
        }  
    }
    services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
        ...ServicesFragment
    }

    productCategories{
        edges{
            node{
                name
                slug
            }
        }
    }

    footer: template(id: "cG9zdDoyMjE5") {
      id
      title
      footerInformationTemplate {
        footerContent
      address {
        addressGoogleLink
        addressLabel
      }
      hoursLabel
      hoursTitle
      informationTitle
      supportEmail
      telephoneNumber
      servicesTitle
      generalPagesTitle
    }
    }
  }
}
`


